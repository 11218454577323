@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Mono&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?&display=swap");
body {
	margin: 0;
	font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
	text-decoration: none;
	max-width: 576px;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	transition: all 500ms ease-in-out;
}

html {
	scroll-behavior: smooth;
}

input:-webkit-autofill {
	transition: background-color 1000s ease-in-out 0s;
}

::-webkit-scrollbar {
	width: 3px;
	position: absolute;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #fb9e12;
	border-radius: 3px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	text-decoration: none;
}
